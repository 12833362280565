import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\common\\Faq.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\common\\ParallaxContainer.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\gitbiz\\procuct\\HRMSPrice.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\headers\\Header1.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\headers\\HeaderPreview2.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\headers\\HeaderPreview4.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\headers\\HeaderPreview5.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\headers\\HeaderPreviewfinal.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\homes\\home-1\\About.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\homes\\home-1\\Features.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\homes\\home-1\\heros\\Hero1.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\homes\\home-1\\heros\\Hero2.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\homes\\home-1\\NewsLetter.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\homes\\home-1\\Portfolio.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\homes\\home-1\\Team.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\homes\\home2\\Pricing.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\homes\\home2\\Promo.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\homes\\home2\\Slider.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\homes\\home2\\Teatimonials2.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\homes\\home2\\Testimonials.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\others\\ClientSlider.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\components\\preview\\Testomonials.jsx");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "C:\\laragon\\www\\Backup\\getbizsolutions\\resonance\\node_modules\\next\\dist\\shared\\lib\\lazy-dynamic\\preload-css.js");
