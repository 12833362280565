"use client";
import { allHomes, menuItems } from "@/data/menu";
import { toggleMobileMenu } from "@/utlis/toggleMobileMenu";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import LanguageSelect from "./components/LanguageSelect";
import { init_classic_menu_resize } from "@/utlis/menuToggle";
import { scrollToElement } from "@/utlis/scrollToElement";
export default function HeaderPreview() {
  const [menuOpen, setMenuOpen] = useState([-1, -1]);

  const toggleParent1 = (i) => {
    const tempMenuOpen = [...menuOpen];
    if (menuOpen[0] == i) {
      tempMenuOpen[0] = -1;
    } else {
      tempMenuOpen[0] = i;
    }
    setMenuOpen(tempMenuOpen);
  };
  const toggleParent2 = (i) => {
    const tempMenuOpen = [...menuOpen];
    if (menuOpen[1] == i) {
      tempMenuOpen[1] = -1;
    } else {
      tempMenuOpen[1] = i;
    }
    setMenuOpen(tempMenuOpen);
  };
  const toggleParent3 = (i) => {
    const tempMenuOpen = [...menuOpen];
    if (menuOpen[2] == i) {
      tempMenuOpen[2] = -1;
    } else {
      tempMenuOpen[2] = i;
    }
    setMenuOpen(tempMenuOpen);
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToElement();
    }, 1000);
    init_classic_menu_resize();
    // window.addEventListener("scroll", addScrollspy);

    window.addEventListener("resize", init_classic_menu_resize);

    return () => {
      // window.removeEventListener("scroll", addScrollspy);
      window.removeEventListener("resize", init_classic_menu_resize);
    };
  }, []);

  const solutionsData = {
    'All Products': {
      title: "Leave Management",
      description: "Manage your inventory and warehouse operations efficiently.",
      image: " ",
      link: "/solutions",
      subItems: [
        {
          title: "Leave Management System",
          subItems: [
            "Leave Policy & Allocation",
            "Leave History",
            "Leave Policy & Allocation",
            "Leave History",
            "Leave Policy & Allocation",
            "Leave History",
          ]
        },
        {
          title: "Employee Management",
          subItems: [
            "Employee View ",
            "Employee Update"
          ]
        },
        {
          title: "Attendance Management",
          subItems: [
            "Attendance Policies",
            "Attendance Adjustment"
          ]
        }
      ]
    },
    'financial': {
      title: "Financial Accounting",
      description: "Streamline your financial accounting processes with our solution.",
      image: "/assets/images/img/law_firm_solutions.webp",
      link: "/solutions",
      subItems: []
    },
    'vehicle': {
      title: "Vehicle Tracking System",
      description: "Track and manage your fleet effectively.",
      image: "/assets/images/img/sales_automation_solutions.webp",
      link: "/solutions",
      subItems: []
    },
  };

  const [selectedSolution, setSelectedSolution] = useState(null);
  const [selectedSubItem, setSelectedSubItem] = useState(null);
  const handleMouseOver = (key) => {
    setSelectedSolution(solutionsData[key]);
    setSelectedSubItem(null);
  };
  const handleMouseOverSolution = (key) => {
    setSelectedSolution(solutionsData[key]);
    setSelectedSubItem(null);  // Reset sub-item selection when hovering over new solution
  };

  const handleMouseOverSubItem = (subItem) => {
    setSelectedSubItem(subItem);
  };

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 390 && window.innerHeight <= 844);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="main-nav-sub full-wrapper">
      {/* Logo  (* Add your text or image to the link tag. Use SVG or PNG image format. 
              If you use a PNG logo image, the image resolution must be equal 200% of the visible logo
              image size for support of retina screens. See details in the template documentation. *) */}
      <div className="nav-logo-wrap local-scroll">
        <Link href={`/`} className="logo">
          <Image
            src="/assets/images/logo-dark.svg"
            alt="Your Company Logo"
            width={105}
            height={34}
          />
        </Link>
      </div>
      {/* Mobile Menu Button */}
      <div
        onClick={toggleMobileMenu}
        className="mobile-nav"
        role="button"
        tabIndex={0}
      >
        <i className="mobile-nav-icon" />
        <span className="visually-hidden">Menu</span>
      </div>
      {/* Main Menu */}
      <div className="inner-nav desktop-nav">
        <ul className="clearlist local-scroll">
          {/* Item With Sub */}
          <li className={menuOpen[0] == 0 ? "js-opened" : ""}>
            <a onClick={() => toggleParent1(0)} className="mn-has-sub active">
              Home <i className="mi-chevron-down" />
            </a>
            {/* Sub */}
            <ul
              className={`mn-sub   ${menuOpen[0] == 0 ? "mobile-sub-active" : ""
                } `}
            >
              {allHomes.map((elm, i) => (
                <li className={menuOpen[1] == i ? "js-opened" : ""} key={i}>
                  <a
                    href="#"
                    onClick={() => toggleParent2(i)}
                    className="mn-has-sub"
                  >
                    {elm.title} <i className="mi-chevron-right right" />
                  </a>
                  {/* Sub Sub */}
                  <ul
                    className={`mn-sub   ${menuOpen[1] == i ? "mobile-sub-active" : ""
                      } `}
                  >
                    {elm.links.map((elm2, i2) => (
                      <li
                        key={i2}
                        className={menuOpen[2] == i2 ? "js-opened" : ""}
                      >
                        <a
                          href="#"
                          onClick={() => toggleParent3(i2)}
                          className="mn-has-sub"
                        >
                          {elm2.title} <i className="mi-chevron-right right" />
                        </a>
                        <ul
                          className={`mn-sub   ${menuOpen[2] == i2 ? "mobile-sub-active" : ""
                            } `}
                        >
                          {elm2.links.map((elm3, i3) => (
                            <li key={i3}>
                              <Link href={elm3.href} target="_blank">
                                {elm3.text}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                    ))}
                  </ul>
                  {/* End Sub Sub */}
                </li>
              ))}
            </ul>
            {/* End Sub */}
          </li>
          {/* End Item With Sub */}


          {/* Item With Sub */}
          {menuItems.slice(5, 8).map((item, index) => (
            <li
              className={menuOpen[0] == index + 1 ? "js-opened" : ""}
              key={index}
            >
              <a
                href="#"
                onClick={() => toggleParent1(index + 1)}
                className="mn-has-sub"
              >
                {item.title} <i className="mi-chevron-down" />
              </a>
              <ul
                className={`mn-sub mn-has-multi ${menuOpen[0] == index + 1 ? "mobile-sub-active" : ""
                  } `}
              >
                {item.subMenu.map((subItem, subIndex) => (
                  <li className="mn-sub-multi" key={subIndex}>
                    {subItem.title && (
                      <span className="mn-group-title">{subItem.title}</span>
                    )}
                    <ul>
                      {subItem.links.map((link, linkIndex) => (
                        <li key={linkIndex}>
                          <Link href={link.href}>
                            {link.icon && <i className={link.icon} />}{" "}
                            {link.text}
                            {/* <p className="">
                            {link.desc}
                            </p> */}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          ))}
          {/* End Item With Sub */}

          {/* my menu */}
          <li
            className={menuOpen[0] == 1 ? "js-opened" : ""}
          >
            <a
              href="#"
              className="mn-has-sub"
            >
              title <i className="mi-chevron-down" />
            </a>
            <ul
              className={`mn-sub mn-has-multi ${menuOpen[0] == 1 ? "mobile-sub-active" : ""
                } `}
            >
              {/* Column 1 */}
              <li className="mn-sub-multi">
                <span className="mn-group-title">All Products</span>
                <ul>
                  <li onMouseOver={() => handleMouseOverSolution('All Products')}>
                    <a href="/solutions">HRMS</a>
                  </li>
                  <li onMouseOver={() => handleMouseOverSolution('financial')}>
                    <a href="/solutions">CRM</a>
                  </li>
                  <li onMouseOver={() => handleMouseOverSolution('vehicle')}>
                    <a href="/solutions">Chat</a>
                  </li>
                </ul>
              </li>

              {/* Column 2 */}
              <li className="mn-sub-multi" style={{
                minWidth: '280px',
                marginLeft: '-30%',
                display: 'flex',
                flexDirection: 'column'
              }}>
                <ul>
                  {/* <li className='!min-w-[350px] !ml-[-30%] flex flex-col'>
                    <img src={selectedSolution ? selectedSolution.image : ' '}
                      className='pt-5 pb-2 rounded-md w-screen' alt={selectedSolution ? selectedSolution.title : 'Solution'} />
                  </li> */}
                <span className="mn-group-title"></span>

                  {selectedSolution && selectedSolution.subItems.map((subItem, index) => (
                    <li key={index} onMouseOver={() => handleMouseOverSubItem(subItem)}>
                      <Link href="/">{subItem.title}</Link>
                    </li>
                  ))}
                </ul>
              </li>

              {/* Column 3 */}
              <li 
              className="mn-sub-multi" style={{
                minWidth: '220px',
                marginLeft: '-30%',
                display: 'flex',
                flexDirection: 'column'
              }}>
                <span className="mn-group-title"></span>
                <ul>
                  {/* <li>
                    <Link href="/">
                      {selectedSubItem ? selectedSubItem.title : (selectedSolution ? selectedSolution.title : 'Please select a product')}
                    </Link>
                  </li> */}
                  {selectedSubItem && selectedSubItem.subItems.map((item, index) => (
                    <li key={index}>
                      <Link href="/">{item}</Link>
                    </li>
                  ))}
                </ul>
              </li>

              {/* Column 4 */}
              {/* {selectedSubItem && selectedSubItem.subItems && (
                <li className="mn-sub-multi">
                  <span className="mn-group-title">{selectedSubItem.title}</span>
                  <ul>
                    {selectedSubItem.subItems.map((subSubItem, index) => (
                      <li key={index}>
                        <Link href="/">
                          <span>icon</span>
                          <span>{subSubItem}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              )} */}


            </ul>
          </li>

          <li className={menuOpen[0] === 1 ? "js-opened" : ""}>
            <a
              href="#"
              onClick={() => toggleParent1(1)}
              className="mn-has-sub"
            >
              Title <i className="mi-chevron-down" />
            </a>
            <ul
              className={`mn-sub mn-has-multi ${menuOpen[0] === 1 ? "mobile-sub-active" : ""}`}
            >
              <div className="absolute shadow-lg w-full left-full top-0 mt-1">
                <div className="flex flex-column gap-4 p-4">
                  <li>
                    {/* Column 1 */}
                    <div className="flex flex-column text-white p-2 border-b border-gray-300">
                      <div className="flex items-center mb-2">
                        <i className="mi-circle-information"></i>
                        <span className="ml-2 text-white text-green-500">&nbsp;Text 1</span>
                      </div>
                      <p className="text-sm  text-white">Description for item 1</p>
                    </div>

                  </li>
                  {/* Column 2 */}
                  <div className="flex flex-column  text-white p-2 border-b border-gray-300">
                    <div className="flex items-center mb-2">
                      <i className="mi-circle-information mr-2"></i>
                      <span className="ml-2  text-white">&nbsp;Text 2</span>
                    </div>
                    <p className="text-sm  text-white">Description for item 2</p>
                  </div>
                  {/* Column 3 */}
                  <div className="flex flex-column  text-white p-2 border-b border-gray-300">
                    <div className="flex items-center mb-2">
                      <i className="mi-circle-information mr-2"></i>
                      <span className="ml-2  text-white">&nbsp;Text 3</span>
                    </div>
                    <p className="text-sm  text-white">Description for item 3</p>
                  </div>
                  {/* Column 4 */}
                  <div className="flex flex-column  text-white p-2">
                    <div className="flex items-center mb-2">
                      <i className="mi-circle-information mr-2"></i>
                      <span className="ml-2  text-white">&nbsp;Text 4</span>
                    </div>
                    <p className="text-sm  text-white">Description for item 4</p>
                  </div>
                </div>
              </div>
            </ul>
          </li>



          {/* Item With Sub */}
          {menuItems.slice(1, 3).map((item, index) => (
            <li
              className={menuOpen[0] == index + 1 ? "js-opened" : ""}
              key={index}
            >
              <a
                href="#"
                onClick={() => toggleParent1(index + 1)}
                className="mn-has-sub"
              >
                {item.title} <i className="mi-chevron-down" />
              </a>
              <ul
                className={`mn-sub mn-has-multi ${menuOpen[0] == index + 1 ? "mobile-sub-active" : ""
                  } `}
              >
                {item.subMenu.map((subItem, subIndex) => (
                  <li className="mn-sub-multi" key={subIndex}>
                    {subItem.title && (
                      <span className="mn-group-title">{subItem.title}</span>
                    )}
                    <ul>
                      {subItem.links.map((link, linkIndex) => (
                        <li key={linkIndex}>
                          <Link href={link.href}>
                            {link.icon && <i className={link.icon} />}{" "}
                            {link.text}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </li>
          ))}
          {/* End Item With Sub */}

          {/* Item With Sub */}
          {menuItems.slice(3, 5).map((item, index) => (
            <li
              className={menuOpen[0] == index + 3 ? "js-opened" : ""}
              key={index}
            >
              <a
                onClick={() => toggleParent1(index + 3)}
                href="#"
                className="mn-has-sub"
              >
                {item.title} <i className="mi-chevron-down" />
              </a>
              {item.subItems && (
                <ul
                  className={`mn-sub  ${menuOpen[0] == index + 3 ? "mobile-sub-active" : ""
                    } `}
                >
                  {item.subItems.map((subItem, subIndex) => (
                    <li
                      className={menuOpen[1] == subIndex ? "js-opened" : ""}
                      key={subIndex}
                    >
                      <a
                        href="#"
                        onClick={() => toggleParent2(subIndex)}
                        className="mn-has-sub"
                      >
                        {subItem.title} <i className="mi-chevron-right right" />
                      </a>
                      {subItem.links && (
                        <ul
                          className={`mn-sub  ${menuOpen[1] == subIndex ? "mobile-sub-active" : ""
                            } `}
                        >
                          {subItem.links.map((link, linkIndex) => (
                            <li key={linkIndex}>
                              <Link href={link.href}>{link.label}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
          {/* End Item With Sub */}

        </ul>
        <ul className="items-end clearlist">
          {/* Languages */}
          <LanguageSelect />
          {/* End Languages */}
          <li>
            <a
              href="https://themeforest.net/user/ib-themes/portfolio"
              target="_blank"
              rel="nofollow noopener"
              className="opacity-1 no-hover"
            >
              <span className="link-hover-anim underline" data-link-animate="y">
                <span className="link-strong link-strong-unhovered">
                  Buy on Envato Market
                </span>
                <span
                  className="link-strong link-strong-hovered"
                  aria-hidden="true"
                >
                  Buy on Envato Market
                </span>
              </span>
            </a>
          </li>
        </ul>
      </div>
      {/* End Main Menu */}
    </div>
  );
}
