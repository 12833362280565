"use client"
import AnimatedText from "@/components/common/AnimatedText";
import { featureItems2new } from "@/data/features";
import Image from "next/image";
// import React from "react";
import React, { useState } from "react";

export default function Promo() {

  const [selectedImage, setSelectedImage] = useState('/assets/images/promo-6.jpg');

  const handleMouseOver = (image) => {
    setSelectedImage(image);
  };

  const handleClick = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className="container position-relative">
      {/* Decorative Dots */}
      <div
        className="decoration-4 d-none d-md-block"
        data-rellax-y=""
        data-rellax-speed="-0.5"
        data-rellax-percentage=".7"
      >
        <Image
          width="103"
          height="223"
          src="/assets/images/decoration-2.svg"
          alt=""
        />
      </div>
      {/* End Decorative Dots */}
      <div className="row">
        <div className="row mb-60 mb-xs-30">
          <div className="col-md-6">
            <h2 className="section-caption mb-xs-10">Find Solutions with us </h2>
            <h3 className="section-title mb-0">
              <AnimatedText text="GetBiz is already easy to use. But we’re still here for you." />
            </h3>
          </div>
          <div className="col-md-5 offset-md-1 relative text-start text-md-end pt-40 pt-sm-20 local-scroll">
            <p>Dive into our range of powerful tools crafted to address your unique business challenges. From enhancing productivity to streamlining operations, our solutions are designed to meet your specific needs.</p>
          </div>
        </div>
        {/* Text */}
        <div className="col-lg-6 mb-md-60 mb-xs-30 d-flex align-items-center">
          <div
            className="wow fadeInUp"
            data-wow-duration="1.2s"
            data-wow-offset={205}
          >

            {/* Features Grid */}
            <div className="row alt-features-grid">
              {/* Features Item */}
              {featureItems2new.map((item, index) => (
                // <div key={index} className={item.className}>
                //   <div className="alt-features-item  "
                //     onMouseOver={() => handleMouseOver(item.image)}
                //     onClick={() => handleClick(item.image)}
                //   >
                //     <div className="alt-features-icon">
                //       <svg
                //         width={24}
                //         height={24}
                //         viewBox="0 0 24 24"
                //         fill="currentColor"
                //         aria-hidden="true"
                //         focusable="false"
                //         xmlns="http://www.w3.org/2000/svg"
                //         fillRule="evenodd"
                //         clipRule="evenodd"
                //       >
                //         <path d={item.path} />
                //       </svg>
                //     </div>
                //     <h3 className="alt-features-title">{item.title}</h3>
                //     <div className="alt-features-descr">{item.description}</div>
                //   </div>
                // </div>
                <div key={index} className={`${item.className} col-md-6 col-lg-4 d-flex align-items-stretch mb-30`}>
                  <div className="services-3-item round text-center">
                    <div className="wow fadeInUpShort" data-wow-offset={50}
                      onMouseOver={() => handleMouseOver(item.image)}
                      onClick={() => handleClick(item.image)}>
                      <div className="alt-features-icon">
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          aria-hidden="true"
                          focusable="false"
                          xmlns="http://www.w3.org/2000/svg"
                          fillRule="evenodd"
                          clipRule="evenodd"
                        >
                          <path d={item.path} />
                        </svg>
                      </div>
                      <h3 className="alt-features-title">{item.title}</h3>
                      <div className="alt-features-descr">{item.description}</div>
                    </div>
                  </div>
                </div>
              ))}


              {/* End Features Item */}
            </div>
            {/* End Features Grid */}
          </div>
        </div>
        {/* End Text */}
        {/* Images */}
        <div className="col-lg-6 d-flex align-items-start">
          <div className="call-action-3-images mt-xs-0 text-end">
            <div className="call-action-3-image-1">
              <Image
                width={821}
                height={821}
                src={selectedImage}
                // src="/assets/images/promo-6.jpg"
                alt="Image Description"
                className="wow scaleOutIn"
                data-wow-duration="1.2s"
                data-wow-offset={205}
              />
              <p>GetBiz is user-friendly, but our support goes further. Explore our powerful tools tailored to tackle your unique business challenges. Enhance productivity with automation, protect your data with advanced security, and scale your operations with our cloud services. </p>

            </div>
            {/* <div className="call-action-3-image-2-wrap d-flex align-items-center">
              <div
                className="call-action-3-image-2"
                data-rellax-y=""
                data-rellax-speed="0.85"
                data-rellax-percentage="0.5"
              >
                <Image
                  width={386}
                  height={500}
                  src="/assets/images/promo-7.jpg"
                  alt="Image Description"
                  className="wow scaleOutIn"
                  data-wow-duration="1.2s"
                />
              </div>
            </div> */}
          </div>
        </div>
        {/* End Images */}
      </div>
    </div>
  );
}
