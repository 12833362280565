"use client";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import Image from "next/image";

// JSON data with image paths and button names
const imageData = [
  {
    id: 1,
    buttonName: "HRMS",
    imagePath: "/assets/images/img/slides/hrms_slide.webp",
  },
  {
    id: 2,
    buttonName: "CRM",
    imagePath: "/assets/images/img/slides/crm_slide.webp",
  },
  {
    id: 3,
    buttonName: "CAMPAIGNS",
    imagePath: "/assets/images/img/slides/campaigns_slide.webp",
  },
];

export default function Slider() {
  const [swiperRef, setSwiperRef] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0); // Index for selected image

  // // Function to update the selected image based on button click
  // const handleButtonClick = (index) => {
  //   setSelectedImage(index);
  //   swiperRef?.slideToLoop(index); // Change to clicked image in Swiper
  // };

  const handleButtonClick = (index) => {
    console.log("Selected Image Index:", index); // Log the index
    setSelectedImage(index);
    swiperRef?.slideToLoop(index); // Change to clicked image in Swiper
  };


  return (
    <div className="container position-relative">
      {/* pt-80 pb-80 pt-sm-70 pb-sm-70 */}
      <div className=" px-4 bg-gray-light-1">
        <div className="row">
        {/* wow fadeInUp */}
          <div className="col-lg-12 -offset-lg-1 ">
            <div className="row">
              <div className="col-md-10 offset-md-1 text-center">
                {/* <h2 className="section-title mb-70 mb-sm-40">Select a Product</h2> */}
                {/* Buttons for HRMS, CRM, CAMPAIGNS */}
                <div className="button-group mt-4 d-flex justify-content-center mb-4">
                  {imageData.map((item, index) => (
                    <button
                      key={item.id}
                      className={` btn btn-mod btn-border-grad btn-small btn-round mx-2 ${selectedImage === index ? "active" : ""}`}
                      onClick={() => handleButtonClick(index)}
                      style={{
                        color: 'black',
                        backgroundColor: selectedImage === index ? 'lightgray' : 'transparent', // Highlight selected button
                        position: 'relative', 
                      }}
                    >
                      {/* <span data-btn-animate="y"> */}
                      {item.buttonName}
                      {selectedImage === index && (
                         <img src="/assets/images/img/checked.png" alt="Check"  style={{
                          backgroundColor: selectedImage === index ? 'lightgray' : 'transparent',
                          position: 'absolute',
                          // right: '10px', // Position the image on the right
                          top: '50%', // Center vertically
                          transform: 'translateY(-110%)', // Adjust for exact centering
                          width: '20px', // Adjust the width as needed
                          height: '20px', // Adjust the height as needed
                          marginLeft: '5px', 
                        }} />
                      )}
                      {/* </span> */}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            {/* Image Slider */}
            <div className="overflow-hidden">
              <Swiper
                onSwiper={setSwiperRef}
                spaceBetween={0}
                slidesPerView={1}
                modules={[Navigation, Autoplay]}
                navigation={{
                  prevEl: ".snbp2",
                  nextEl: ".snbn2",
                }}
                autoplay={{
                  delay: 1600, // Delay between slides (in milliseconds)
                  disableOnInteraction: false, // Keep autoplay after user interactions
                }}
                loop={true} // Enable looping
                className="testimonials-slider-1 pb-xs-80 overflow-visible position-static"
              >
                {imageData.map((item, index) => (
                  <SwiperSlide key={item.id}>
                    <div className="row pb-80 nmt-80">
                      <div className="col-lg-12 d-flex justify-content-center align-items-center">
                        <div className="position-relative overflow-hidden">
                          <Image
                            width={960}
                            height={539}
                            src={item.imagePath}
                            className="image-fullwidth relative"
                            alt={item.buttonName}
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {/* Slider Controls */}
              {/* <div className="text-center mt-4">
                <div className="owl-prev snbp2" role="button" tabIndex="0">
                  <span className="visually-hidden">Previous Slide</span>
                  <i className="mi-arrow-left" aria-hidden="true"></i>
                </div>
                <div className="owl-next snbn2" role="button" tabIndex="0">
                  <span className="visually-hidden">Next Slide</span>
                  <i className="mi-arrow-right" aria-hidden="true"></i>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
