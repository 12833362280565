
// import "./styles.css";
import { useEffect } from "react";
import { getCalApi } from "@calcom/embed-react";

export default function Book() {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      cal("floatingButton", {
        calLink: "getbiz.solutions/30min",
        config: {
          name: "John",
          email: "john@example.com",
          "metadata[employeeId]": "101"
        }
      });
      cal("ui", {
        styles: {
          branding: { brandColor: "#000000" }
        }
      });
    })();
  }, []);

  return <h1 className="App"></h1>;
}
