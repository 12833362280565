"use client";
import { crm, pricing2 } from "@/data/pricing";

import Image from "next/image";
import React, { useState } from "react";

export default function Pricing() {
  const [isYearly, setIsYearly] = useState(false);
  return (
    <>
      {/* Nav Tabs */}
      {/* <div className="mb-60 mb-sm-40 text-center">
        <ul className="nav nav-tabs tpl-minimal-tabs animate" role="tablist">
          <li
            onClick={() => setIsYearly(true)}
            className="nav-item"
            role="presentation"
          >
            <a className={`nav-link ${isYearly ? "active" : ""} `}>Annual</a>
          </li>
          <li
            onClick={() => setIsYearly(false)}
            className="nav-item"
            role="presentation"
          >
            <a className={`nav-link ${!isYearly ? "active" : ""} `}>Monthly</a>
          </li>
        </ul>
        <div className="small text-gray mt-10">
          Save up to <strong>15%</strong> with Annual Plan.
        </div>
      </div> */}
      {/* End Nav Tabs */}
      {/* Tab panes */}
      <div className="tab-content tpl-minimal-tabs-cont position-relative">
        {/* Decorative Waves */}
        {/* <div
          className="decoration-10 d-none d-sm-block z-index-n1"
          data-rellax-y=""
          data-rellax-speed="-0.7"
          data-rellax-percentage="0.37"
        >
          <Image
            src="/assets/images/decoration-3.svg"
            className="svg-shape"
            width={148}
            height={148}
            alt=""
          />
        </div> */}
        {/* End Decorative Waves */}
        <div className="tab-pane show active" id="tab-annual" role="tabpanel">
          <div className="row mt-n30">
            {/* Pricing Item */}
            <div
              className="col-md-6 col-lg-6 mt-30 d-flex align-items-stretch"
            >
              <div className="pricing-item">
                <div className="pricing-item-inner round">
                  <div className="pricing-wrap">
                    <div className="pricing-icon mb-0">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width={56}
                        height={64}
                        viewBox={`0 0 56 64 `}
                        aria-hidden="true"
                      >
                        <path d="M28 8c15.5 0 28 12.5 28 28 0 7.125-2.625 13.5-7 18.5-0.5 0.5-0.875 1-1.375 1.5l-1.25-1.25-0.125-0.25c-2.375-2.25-5.125-4.125-8.125-5.375-3.25-1.375-6.625-2-10.125-2s-6.875 0.625-10.125 2c-3 1.25-5.75 3.125-8.125 5.375l-0.125 0.25-1.25 1.25c-0.5-0.5-0.875-1-1.375-1.5-4.375-5-7-11.375-7-18.5 0-15.5 12.5-28 28-28zM52 46.125c1.25-2.875 1.875-6 2-9.125h-4v-2h4c-0.125-3.125-0.75-6.25-2-9.125-1.125-2.75-2.875-5.25-4.875-7.5l-3.375 3.375-1.375-1.375h-0.125l3.375-3.375c-2.25-2.125-4.75-3.875-7.5-5-2.875-1.25-6-1.875-9.25-2v4.75h-2v-4.75c-3.125 0.125-6.125 0.75-9 2-2.75 1.25-5.375 2.875-7.625 5l3.375 3.375-1.375 1.375-3.375-3.375c-2 2.25-3.75 4.75-4.875 7.5-1.25 2.875-1.875 6.125-2 9.25h4v2h-4c0.125 3.125 0.75 6.125 2 9 1.125 2.5 2.5 4.875 4.375 7 5-5 12-8 19.625-8s14.625 3 19.625 8c1.875-2.125 3.25-4.5 4.375-7zM42 21.875l0.25 0.125-9.375 10.5c0.75 1 1.125 2.25 1.125 3.5 0 3.25-2.75 6-6 6-1.25 0-2.375-0.5-3.375-1.125l-1.625 1.5-1.375-1.375 1.5-1.5c-0.75-1-1.125-2.25-1.125-3.5 0-3.25 2.75-6 6-6 1.25 0 2.375 0.375 3.375 1zM28 40c2.25 0 4-1.75 4-4s-1.75-4-4-4-4 1.75-4 4 1.75 4 4 4z" />
                      </svg>
                    </div>
                    <h4 className="pricing-title">Go Monthly</h4>
                    <div className="pricing-num">
                      <sup>$</sup>
                      {isYearly
                        ? Math.round((199 * 12 * 75) / 100)
                        : 199}
                    </div>
                    <div className="pr-per">
                      {isYearly ? "per year" : "per month"}
                    </div>
                    <div className="pricing-features">
                      <ul className="pr-list">
                        <li >
                          <i className="mi-check" /> 1 Gb Storage
                        </li>
                        <li >
                          <i className="mi-check" /> 3 Domain Names
                        </li>
                        <li >
                          <i className="mi-check" /> 5 FTP Users
                        </li>
                        <li className="opacity-055">
                          <i className="mi-close" /> Free Support
                        </li>
                        <li className="opacity-055">
                          <i className="mi-close" /> Free SSL Certificate
                        </li>
                      </ul>
                    </div>
                    <div className="mt-auto local-scroll">
                      <a
                        href="#"
                        className="btn btn-mod btn-medium btn-white btn-round btn-hover-anim btn-full"
                      >
                        <span>Buy Go Monthly</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-6 mt-30 d-flex align-items-stretch"
            >
              <div className="pricing-item">
                <div className="pricing-item-inner round">
                  <div className="pricing-wrap">
                    <div className="pricing-icon mb-0">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width={56}
                        height={64}
                        viewBox={`0 0 56 64`}
                        aria-hidden="true"
                      >
                        <path d="M28 8c15.5 0 28 12.5 28 28 0 7.125-2.625 13.5-7 18.5-0.5 0.5-0.875 1-1.375 1.5l-1.25-1.25-0.125-0.25c-2.375-2.25-5.125-4.125-8.125-5.375-3.25-1.375-6.625-2-10.125-2s-6.875 0.625-10.125 2c-3 1.25-5.75 3.125-8.125 5.375l-0.125 0.25-1.25 1.25c-0.5-0.5-0.875-1-1.375-1.5-4.375-5-7-11.375-7-18.5 0-15.5 12.5-28 28-28zM52 46.125c1.25-2.875 1.875-6 2-9.125h-4v-2h4c-0.125-3.125-0.75-6.25-2-9.125-1.125-2.75-2.875-5.25-4.875-7.5l-3.375 3.375-1.375-1.375h-0.125l3.375-3.375c-2.25-2.125-4.75-3.875-7.5-5-2.875-1.25-6-1.875-9.25-2v4.75h-2v-4.75c-3.125 0.125-6.125 0.75-9 2-2.75 1.25-5.375 2.875-7.625 5l3.375 3.375-1.375 1.375-3.375-3.375c-2 2.25-3.75 4.75-4.875 7.5-1.25 2.875-1.875 6.125-2 9.25h4v2h-4c0.125 3.125 0.75 6.125 2 9 1.125 2.5 2.5 4.875 4.375 7 5-5 12-8 19.625-8s14.625 3 19.625 8c1.875-2.125 3.25-4.5 4.375-7zM42 21.875l0.25 0.125-9.375 10.5c0.75 1 1.125 2.25 1.125 3.5 0 3.25-2.75 6-6 6-1.25 0-2.375-0.5-3.375-1.125l-1.625 1.5-1.375-1.375 1.5-1.5c-0.75-1-1.125-2.25-1.125-3.5 0-3.25 2.75-6 6-6 1.25 0 2.375 0.375 3.375 1zM28 40c2.25 0 4-1.75 4-4s-1.75-4-4-4-4 1.75-4 4 1.75 4 4 4z" />
                      </svg>
                    </div>
                    <h4 className="pricing-title">Go Annually</h4>
                    <div className="pricing-num">
                      <sup>$</sup>
                      199
                    </div>
                    <div className="pr-per">
                      per year
                    </div>
                    <div className="pricing-features">
                      <ul className="pr-list">
                        <li>
                          <i className="mi-check" /> 1 Gb Storage
                        </li>
                        <li>
                          <i className="mi-check" /> 3 Domain Names
                        </li>
                        <li>
                          <i className="mi-check" /> 5 FTP Users
                        </li>
                        <li className="opacity-055">
                          <i className="mi-close" /> Free Support
                        </li>
                        <li className="opacity-055">
                          <i className="mi-close" /> Free SSL Certificate
                        </li>
                      </ul>
                    </div>
                    <div className="mt-auto local-scroll">
                      <a
                        href="#"
                        className="btn btn-mod btn-medium btn-white btn-round btn-hover-anim btn-full"
                      >
                        <span>Buy Go Monthly</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ))} */}
            {/* {crm.map((elm, i) => (
              <div
                key={i}
                className="col-md-6 col-lg-6 mt-30 d-flex align-items-stretch"
              >
                <div className="pricing-item">
                  <div className="pricing-item-inner round">
                    <div className="pricing-wrap">
                      <div className="pricing-icon mb-0">
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width={elm.width}
                          height={elm.height}
                          viewBox={`0 0 ${elm.width} ${elm.height}`}
                          aria-hidden="true"
                        >
                          <path d={elm.svgPath} />
                        </svg>
                      </div>
                      <h4 className="pricing-title">{elm.title}</h4>
                      <div className="pricing-num">
                        <sup>$</sup>
                        {isYearly
                          ? Math.round((elm.price * 12 * 75) / 100)
                          : elm.price}
                      </div>
                      <div className="pr-per">
                        {isYearly ? "per year" : "per month"}
                      </div>
                      <div className="pricing-features">
                        <ul className="pr-list">
                          {elm.features?.map((elm, i) => (
                            <li key={i}>
                              <i className="mi-check" /> {elm}
                            </li>
                          ))}
                          {elm.disabledFeatures?.map((elm, i) => (
                            <li key={i} className="opacity-055">
                              <i className="mi-close" /> {elm}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="mt-auto local-scroll">
                        <a
                          href="#"
                          className="btn btn-mod btn-medium btn-white btn-round btn-hover-anim btn-full"
                        >
                          <span>Buy {elm.title}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))} */}
            {/* End Pricing Item */}

            {/* End Pricing Item */}
          </div>
        </div>
      </div>
      {/* End Tab panes */}
    </>
  );
}
